import { useState, useEffect } from 'react';
import { Container, Typography, Box, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress, Paper } from '@mui/material';
import config from '../config'; // Import the configuration
import { useAuth } from '../auth';

const AnalyzeReport = () => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [status, setStatus] = useState('');
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth(); 

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/records`, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFiles(data.files);
      } catch (error) {
        console.error('Error fetching file list:', error);
      }
    };

    fetchFiles();
  }, [user.accessToken]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.value);
  };
  
  const handleAnalyze = async () => {
    setLoading(true);
    setStatus('');
    setReport(null);

    try {
      const eventSource = new EventSource(`${config.API_BASE_URL}/analyze/${selectedFile}?token=${user.accessToken}`);

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.status === 'Completed' || data.status === 'Test') {
          setReport(data.report);
          setLoading(false);
          eventSource.close();
        } else {
          setStatus(data.status);
        }
      };

      eventSource.onerror = (error) => {
        console.error('Error during analysis:', error);
        setStatus('An error occurred during analysis.');
        setLoading(false);
        eventSource.close();
      };
    } catch (error) {
      console.error('Error during analysis:', error);
      setStatus('An error occurred during analysis.');
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" gutterBottom>Analyze & Generate Report</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Select File for Analysis</InputLabel>
          <Select
            value={selectedFile}
            onChange={handleFileChange}
            label="Select File for Analysis"
          >
            {files.map((file, index) => (
              <MenuItem key={index} value={file}>{file}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAnalyze}
          disabled={loading || !selectedFile}
        >
          {loading ? <CircularProgress size={24} /> : 'Analyze'}
        </Button>
        {status && (
          <Typography variant="body1" sx={{ mt: 2 }}>
            {status}
          </Typography>
        )}
        {report && (
          <Paper sx={{ mt: 4, p: 2 }}>
            <Typography variant="h6">Report</Typography>
            <Typography variant="body1">{report.summary}</Typography>
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default AnalyzeReport;
