import { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Box, FormControl, InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button } from '@mui/material';
import config from '../config'; // Import the configuration
import { useAuth } from '../auth';

const DisplayRecord = () => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [recordData, setRecordData] = useState(null);
  const { user } = useAuth();

  const fetchFiles = useCallback(async () => {
    try {
      // console.log('user.accessToken is:', user.accessToken);
      const response = await fetch(`${config.API_BASE_URL}/records`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setFiles(data.files);
    } catch (error) {
      console.error('Error fetching file list:', error);
      throw new Error('ERR_CONNECTION_REFUSED');
    }
  }, [user.accessToken]);

  useEffect(() => {
    if (user) {
      fetchFiles();
    }
  }, [user, fetchFiles]);

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.value);
    try {
      const response = await fetch(`${config.API_BASE_URL}/record/${event.target.value}`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRecordData(data);
    } catch (error) {
      console.error('Error fetching record:', error);
      throw new Error('ERR_CONNECTION_REFUSED');
    }
  };

  const handleCsvChange = async (event) => {
    const csvFile = event.target.files[0];
    if (csvFile) {
      const formData = new FormData();
      formData.append('file', csvFile);

      try {
        const response = await fetch(`${config.API_BASE_URL}/upload_csv`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${user.accessToken}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        alert(data.message);
        // Refresh file list
        fetchFiles();
      } catch (error) {
        console.error('Error uploading CSV:', error);
        throw new Error('ERR_CONNECTION_REFUSED');
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" gutterBottom>Display Record</Typography>
        <Button
          variant="contained"
          component="label"
        >
          Add Other Records
          <input
            type="file"
            accept=".csv"
            hidden
            onChange={handleCsvChange}
          />
        </Button>
        <FormControl fullWidth margin="normal">
          <InputLabel>Select Record</InputLabel>
          <Select
            value={selectedFile}
            onChange={handleFileChange}
            label="Select Record"
          >
            {files.map((file, index) => (
              <MenuItem key={index} value={file}>{file}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {recordData && (
          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table>
              <TableBody>
                {Array.isArray(recordData) ? (
                  recordData.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{entry.key}</TableCell>
                      <TableCell>{entry.value}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  Object.entries(recordData).map(([key, value], index) => (
                    <TableRow key={index}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{value}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Container>
  );
};

export default DisplayRecord;
