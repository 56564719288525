// src/routes/NormalUserRoute.js
import { useAuth } from '../auth';
import { Navigate } from 'react-router-dom';

const NormalUserRoute = ({ element: Component, ...rest }) => {
  const { user, role } = useAuth();
  return user && (role === 'admin' || role === 'normal-user') ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

export default NormalUserRoute;