// src/authService.js
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { jwtDecode } from "jwt-decode";
import AWS from 'aws-sdk/global';
// import AWS_CognitoIdentity from 'aws-sdk/clients/cognitoidentity';
// import AWS_CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';
import awsconfig from './aws-exports';
import config from './config'; 

AWS.config.update({ region: awsconfig.region });

const userPool = new CognitoUserPool({
    UserPoolId: awsconfig.userPoolId,
    ClientId: awsconfig.userPoolWebClientId,
});

export const signIn = (Username, Password) => {
    return new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({ Username, Password });
        const userData = { Username, Pool: userPool };
        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                const accessToken = result.getAccessToken().getJwtToken();
                const idToken = result.getIdToken().getJwtToken();

                // Decode the ID token to extract groups
                const decodedToken = jwtDecode(idToken);
                const userGroups = decodedToken['cognito:groups'] || [];

                resolve({ cognitoUser, userGroups, accessToken, idToken });
            },
            onFailure: (err) => {
                reject(err);
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                resolve({ challengeName: 'NEW_PASSWORD_REQUIRED', user: cognitoUser, userAttributes, requiredAttributes });
            }
        });
    });
};

export const completeNewPassword = (cognitoUser, newPassword) => {
    return new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
            onSuccess: (result) => {
                const accessToken = result.getAccessToken().getJwtToken();
                const idToken = result.getIdToken().getJwtToken();

                // Decode the ID token to extract groups
                const decodedToken = jwtDecode(idToken);
                const userGroups = decodedToken['cognito:groups'] || [];

                resolve({ cognitoUser, userGroups, accessToken, idToken });
            },
            onFailure: (err) => {
                reject(err);
            }
        });
    });
};

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser();

        if (!cognitoUser) {
            return reject(new Error('No user is currently signed in.'));
        }

        cognitoUser.getSession((err, session) => {
            if (err) {
                return reject(err);
            }

            const accessToken = session.getAccessToken().getJwtToken();
            const idToken = session.getIdToken().getJwtToken();

            // Decode the ID token to extract groups
            const decodedToken = jwtDecode(idToken);
            const userGroups = decodedToken['cognito:groups'] || [];

            resolve({ cognitoUser, userGroups, accessToken, idToken });
        });
    });
};

export const signOut = async () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
        const session = cognitoUser.getSignInUserSession();
        if (session) {
            const accessToken = session.getAccessToken().getJwtToken();
            cognitoUser.signOut();
            try {
                const response = await fetch(`${config.API_BASE_URL}/logout`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to logout from backend');
                }
            } catch (error) {
                console.error('Error logging out from backend:', error);
            }
        } else {
            cognitoUser.signOut();
            console.error('No active session found for user.');
        }
    } else {
        console.error('No cognito user found.');
    }
};