import { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signIn, completeNewPassword, getCurrentUser } from '../authService';
import { useAuth } from '../auth';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const [cognitoUser, setCognitoUser] = useState(null);
    const navigate = useNavigate();
    const { user, setUser, setRole, logout } = useAuth();

    useEffect(() => {
        // Check if a user is already logged in
        console.log('Checking if a user logged in...');
        getCurrentUser()
            .then(result => {
                if (result) {
                    // console.log('Current user:', result);
                    setUser(result);
                    const userRole = result.userGroups.includes('admin') ? 'admin' : 'normal-user';
                    setRole(userRole);
                    // console.log('Navigating to:', userRole === 'admin' ? '/analyze-report' : '/questionnaire');
                    // navigate(userRole === 'admin' ? '/analyze-report' : '/questionnaire');
                    navigate('/questionnaire');
                }
            })
            .catch(err => {
                console.error('No user is currently signed in:', err);
            });
    }, [setUser, setRole, navigate]);

    const handleLogin = async (event) => {
        event.preventDefault();
        setError(null);

        try {
            const result = await signIn(username, password);

            if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setNewPasswordRequired(true);
                setCognitoUser(result.user);
            } else {
                setUser(result);
                const userRole = result.userGroups.includes('admin') ? 'admin' : 'normal-user';
                setRole(userRole);
                // console.log('Navigating to:', userRole === 'admin' ? '/analyze-report' : '/questionnaire');
                navigate('/questionnaire');
            }
        } catch (err) {
            setError(err.message || 'Invalid username or password');
            console.error('Login error:', err);
        }
    };

    const handleNewPassword = async (event) => {
        event.preventDefault();
        setError(null);

        try {
            const result = await completeNewPassword(cognitoUser, newPassword);
            // console.log('New password result:', result);

            setUser(result);
            const userRole = result.userGroups.includes('admin') ? 'admin' : 'normal-user';
            setRole(userRole);
            //console.log('Navigating to:', userRole === 'admin' ? '/analyze-report' : '/questionnaire');
            navigate('/questionnaire');
        } catch (err) {
            setError(err.message || 'Failed to set new password');
            console.error('New password error:', err);
        }
    };

    const handleSignOut = async () => {
        console.log('Signing out...');
        await logout();
        navigate('/login');
    };

    // console.log('Rendering Login component. Current user:', user);

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 5 }}>
                <Typography variant="h4" gutterBottom>Login</Typography>
                {error && <Typography color="error">{error}</Typography>}
                {user ? (
                    <Box>
                        <Typography variant="h6">You are already logged in</Typography>
                        <Button variant="contained" color="secondary" fullWidth sx={{ mt: 2 }} onClick={handleSignOut}>Sign Out</Button>
                    </Box>
                ) : (
                    !newPasswordRequired ? (
                        <form onSubmit={handleLogin}>
                            <TextField
                                label="Username"
                                fullWidth
                                margin="normal"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <TextField
                                label="Password"
                                type="password"
                                fullWidth
                                margin="normal"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>Login</Button>
                        </form>
                    ) : (
                        <form onSubmit={handleNewPassword}>
                            <TextField
                                label="New Password"
                                type="password"
                                fullWidth
                                margin="normal"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>Set New Password</Button>
                        </form>
                    )
                )}
            </Box>
        </Container>
    );
};

export default Login;
