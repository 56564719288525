import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withErrorBoundary = (Component) => {
    return (props) => {
        const [hasError, setHasError] = useState(false);
        const navigate = useNavigate();

        useEffect(() => {
            const handleFetchError = (event) => {
                const errorMessage = event.reason?.message || event.message || 'Unknown error';
                console.error('Error captured by withErrorBoundary:', errorMessage);
                if (event.reason?.name === 'AbortError' || errorMessage.includes('ERR_CONNECTION_REFUSED')) {
                    setHasError(true);
                    navigate('/unauthorized');
                }

            };

            window.addEventListener('unhandledrejection', handleFetchError);
            window.addEventListener('error', handleFetchError);

            return () => {
                window.removeEventListener('unhandledrejection', handleFetchError);
                window.removeEventListener('error', handleFetchError);
            };
        }, [navigate]);

        return hasError ? null : <Component {...props} />;
    };
};

export default withErrorBoundary;
