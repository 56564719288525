// src/routes/AdminRoute.js
import { useAuth } from '../auth';
import { Navigate } from 'react-router-dom';

const AdminRoute = ({ element: Component, ...rest }) => {
  const { user, role } = useAuth();
  return user && role === 'admin' ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

export default AdminRoute;