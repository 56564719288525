import { useState, useEffect, createContext, useContext } from 'react';
import { getCurrentUser, signOut } from './authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);

    useEffect(() => {
        getCurrentUser()
            .then((userData) => {
                if (userData) {
                    // console.log('User data from getCurrentUser:', userData);
                    setUser({
                        cognitoUser: userData.cognitoUser,
                        userGroups: userData.userGroups,
                        accessToken: userData.accessToken,
                        idToken: userData.idToken
                    });
                    const userRole = userData.userGroups.includes('admin') ? 'admin' : 'normal-user';
                    setRole(userRole);
                    // console.log('AuthProvider userRole:', userRole);
                }
            })
            .catch((err) => {
                console.error('Error fetching current user:', err);
                setUser(null);
                setRole(null);
            });
    }, []);

    const logout = async () => {
        await signOut();
        setUser(null);
        setRole(null);
    };

    return (
        <AuthContext.Provider value={{ user, role, setUser, setRole, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
