import { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText, Toolbar, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth';
import config from '../config';

const drawerWidth = 240;

const Navigation = () => {
  const { user, role  } = useAuth();
  // const [status, setStatus] = useState('Connecting...');
  const [status, setStatus] = useState('Connecting...');

  const checkHealth = async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/health`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setStatus('Online');
      } else {
        setStatus('Offline');
      }
    } catch (error) {
      setStatus('Offline');
    }
  };

  useEffect(() => {
    checkHealth();
  }, []);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar>
        <Box display="flex" alignItems="center">
          <img src="/app_icon.png" alt="dragonfly icon" style={{ width: 24, height: 24, marginRight: 8 }} />
          <Typography variant="h6" noWrap component="div">
            Lifestyle Advisor
          </Typography>
        </Box>
      </Toolbar>
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {!user ? (
            <>
            <ListItem button component={Link} to="/login">
              <ListItemText primary="Login" />
            </ListItem>
            </>
          ) : (
            <>
            <ListItem button component={Link} to="/profile">
              <ListItemText primary="User Profile" />
            </ListItem>
            <ListItem button component={Link} to="/questionnaire">
              <ListItemText primary="Lifestyle Questionnaire" />
            </ListItem>
            <ListItem button component={Link} to="/display-record">
              <ListItemText primary="Display/Add Records" />
            </ListItem>
            {role === 'admin' && (
                <>
                  <ListItem button component={Link} to="/analyze-report">
                    <ListItemText primary="Analyze & Generate Report" />
                  </ListItem>
                  <ListItem button component={Link} to="/download-report">
                    <ListItemText primary="Download Report" />
                  </ListItem>
                </>
            )}
            </>
          )}

        </List>
      </Box>
    </Drawer>
  );
};

export default Navigation;

