import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Container, Typography, Box, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import config from '../config'; 
import { useAuth } from '../auth';

const absiData = {
  Male: [
      { age: 10, mean: 0.07949, sd: 0.00370 },
      { age: 15, mean: 0.07739, sd: 0.00379 },
      { age: 20, mean: 0.07728, sd: 0.00377 },
      { age: 25, mean: 0.07858, sd: 0.00376 },
      { age: 30, mean: 0.07951, sd: 0.00374 },
      { age: 35, mean: 0.08013, sd: 0.00372 },
      { age: 40, mean: 0.08087, sd: 0.00370 },
      { age: 45, mean: 0.08165, sd: 0.00368 },
      { age: 50, mean: 0.08260, sd: 0.00366 },
      { age: 55, mean: 0.08352, sd: 0.00364 },
      { age: 60, mean: 0.08436, sd: 0.00362 },
      { age: 65, mean: 0.08522, sd: 0.00360 },
      { age: 70, mean: 0.08591, sd: 0.00359 },
      { age: 75, mean: 0.08653, sd: 0.00357 },
      { age: 80, mean: 0.08705, sd: 0.00355 },
      { age: 85, mean: 0.08811, sd: 0.00356 }
  ],
  Female: [
      { age: 10, mean: 0.07829, sd: 0.00354 },
      { age: 15, mean: 0.07755, sd: 0.00369 },
      { age: 20, mean: 0.07711, sd: 0.00373 },
      { age: 25, mean: 0.07754, sd: 0.00374 },
      { age: 30, mean: 0.07783, sd: 0.00374 },
      { age: 35, mean: 0.07852, sd: 0.00372 },
      { age: 40, mean: 0.07894, sd: 0.00370 },
      { age: 45, mean: 0.07941, sd: 0.00368 },
      { age: 50, mean: 0.08001, sd: 0.00366 },
      { age: 55, mean: 0.08054, sd: 0.00364 },
      { age: 60, mean: 0.08097, sd: 0.00362 },
      { age: 65, mean: 0.08143, sd: 0.00360 },
      { age: 70, mean: 0.08179, sd: 0.00359 },
      { age: 75, mean: 0.08213, sd: 0.00357 },
      { age: 80, mean: 0.08249, sd: 0.00355 },
      { age: 85, mean: 0.08289, sd: 0.00356 }
  ]
};

function getAbsiStats(sex, age) {
  const data = absiData[sex];
  if (!data) {
      return { mean: null, sd: null };
  }

  let closest = data[0];
  for (let i = 1; i < data.length; i++) {
      if (Math.abs(data[i].age - age) < Math.abs(closest.age - age)) {
          closest = data[i];
      }
  }

  return { mean: closest.mean, sd: closest.sd };
}


const Questionnaire = () => {
  const [questionnaires, setQuestionnaires] = useState([]);
  const [currentSection, setCurrentSection] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [bmi, setBmi] = useState('');
  const [waistHipR, setWaistHipR] = useState('');
  const [absiZScore, setAbsiZScore] = useState('');
  const { user } = useAuth();
  const [hasSavedData, setHasSavedData] = useState(false);

  const { control, register, handleSubmit, setValue, watch, getValues, reset, formState: { errors } } = useForm({
    defaultValues: formValues,
  });

  const watchSex = watch('basic_sex'); // Watch sex selection
  const watchHeight = watch('coach_height');
  const watchWeight = watch('coach_weight');
  const watchWaist = watch('coach_waist_circumference');
  const watchHip = watch('coach_hip_circumference');
  const watchAge = watch('basic_age');

  useEffect(() => {
    fetch('/questions.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setQuestionnaires(data.questionnaires);
      })
      .catch(error => {
        console.error('Error fetching the JSON file:', error);
      });

    // Preload data if available in localStorage
    const savedData = localStorage.getItem('questionnaireCachedData');
    if (savedData) {
      setHasSavedData(true);
    }
      
  }, []);

  useEffect(() => {
    // Update form values when navigating to a section
    reset(formValues);
  }, [currentSection, reset, formValues]);

  useEffect(() => {
    // Set the current date to formValues
    const currentDate = new Date().toISOString().split('T')[0];
    setValue('current_date', currentDate);
  }, [setValue]);

  useEffect(() => {
    // Calculate waist-to-hip ratio
    const waist = parseFloat(watchWaist);
    const hip = parseFloat(watchHip);

    if (hip > 0 && waist> 0) {

      const waistHipRatio = waist / hip;

      setWaistHipR(`${waistHipRatio.toFixed(4)}`);
      setValue('coach_waisthip_ratio', `${waistHipRatio.toFixed(4)}`);
    }
  }, [watchWaist, watchHip, setValue]);

  useEffect(() => {
    // Calculate absi-z whenever any of the following changes
    const height = parseFloat(watchHeight);
    const weight = parseFloat(watchWeight);
    const waist = parseFloat(watchWaist);
    const age = parseFloat(watchAge);
    const sex = watchSex;

    if (height > 0 && weight > 0 && waist> 0 && age > 0 && sex) {
      const { absi: absiScore, z_score: zScore } = calculateAbsiZScore(waist, height, weight, age, sex);
      //setAbsiZScore(zScore.toFixed(2));

      let absiCategory = '';
      if (zScore < -0.868) {
        absiCategory = 'Very low < -0.868';
      } else if (zScore < -0.272) {
        absiCategory = 'Low -0.868 - -0.272';
      } else if (zScore < +0.229) {
        absiCategory = 'Average -0.272 - +0.229';
      } else if (zScore < +0.798) {
        absiCategory = 'High +0.229 - +0.798';
      } else {
        absiCategory = 'Very high > +0.798';
      }

      setAbsiZScore(`Score: ${absiScore.toFixed(4)} Z-score: ${zScore.toFixed(3)} (${absiCategory})`);
      setValue('coach_absiz_score', `Score: ${absiScore.toFixed(4)} Z-score: ${zScore.toFixed(3)} (${absiCategory})`);
    }
  }, [watchHeight, watchWeight, watchAge, watchWaist, watchSex, setValue]);

  const calculateAbsiZScore = (waist, height, weight, age, sex) => {
    const bmi = weight / ((height / 100) ** 2);
    const absi = (waist / 100) / (bmi ** (2/3) * (height / 100) ** (1/2));

    console.log(`BMI: ${bmi}`);
    console.log(`ABSI: ${absi}`);

    const { mean: mean_absi, sd: sd_absi } = getAbsiStats(sex || 'Female', age);
    
    // if (sex === "Male") {
    //     mean_absi = 0.00009432 * age + 0.07667519;
    //     sd_absi = 0.00000815 * age + 0.00272615;
    // } else if (sex === "Female") {
    //     mean_absi = 0.00009162 * age + 0.07568291;
    //     sd_absi = 0.00000852 * age + 0.00258144;
    // } else {
    //     mean_absi = 0.00009297 * age + 0.07617905;
    //     sd_absi = 0.00000834 * age + 0.002653795;
    // }

    const z_score = (absi - mean_absi) / sd_absi;
    console.log(`ABSI-Z-Score: ${z_score}`);
    return { absi, z_score };
  };

  useEffect(() => {
    // Calculate BMI whenever height or weight changes
    const height = parseFloat(watchHeight);
    const weight = parseFloat(watchWeight);

    if (height > 0 && weight > 0) {
      const calculatedBmi = (weight / ((height / 100) ** 2)).toFixed(1);
      let bmiCategory = '';

      if (calculatedBmi < 18.5) {
        bmiCategory = 'Underweight < 18.5';
      } else if (calculatedBmi < 25) {
        bmiCategory = 'Normal 18.5 - 24.9';
      } else if (calculatedBmi < 30) {
        bmiCategory = 'Overweight Pre-Obese 25 - 29.9';
      } else if (calculatedBmi < 35) {
        bmiCategory = 'Obese I  30 - 34.9';
      } else if (calculatedBmi < 40) {
        bmiCategory = 'Obese II  35 - 39.9';
      } else {
        bmiCategory = 'Obese III > 40';
      }

      setBmi(`${calculatedBmi} (${bmiCategory})`);
      setValue('coach_bmi', `${calculatedBmi} (${bmiCategory})`);
    }
  }, [watchHeight, watchWeight, setValue]);

  const shouldShowQuestion = (question) => {
    if (!question.conditions) {
      return true;
    }
    if (question.conditions.basic_sex && watchSex !== question.conditions.basic_sex) {
      return false;
    }
    return true;
  };

  const processFormData = (data) => {
    const processedData = {};

    questionnaires.forEach(section => {
      section.questions.forEach(question => {
        if (shouldShowQuestion(question)) {
          const name = question.name;
          const questionText = question.question;
          const value = data[name];

          if (question.type === 'checkbox' && typeof value === 'object') {
            const selectedOptions = [];
            Object.keys(value).forEach(subKey => {
              if (value[subKey] === true) {
                const option = question.options.find(opt => opt.value === subKey);
                if (option && option.requiresText && data[`${name}_${subKey}`]) {
                  selectedOptions.push(`${subKey} ${data[`${name}_${subKey}`]}`);
                } else {
                  selectedOptions.push(subKey);
                }
              }
            });
            processedData[questionText] = selectedOptions.join('; ');
          } else if (question.type === 'select' && Array.isArray(value)) {
            processedData[questionText] = value.join('; ');
          } else {
            processedData[questionText] = value;
          }
        }
      });
    });

    return processedData;
  };

  const loadSavedData = () => {
    const savedData = localStorage.getItem('questionnaireCachedData');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormValues(parsedData);
      reset(parsedData);
    }
  };
  
  const onSubmit = async (data) => {
    console.log('Data:', data);
    // Store data locally prior to submission
    localStorage.setItem('questionnaireCachedData', JSON.stringify(data));

    const processedData = processFormData(data);
    const requestBody = JSON.stringify(processedData);
    
    console.log('Processed Data:', processedData);
    console.log('Request Body:', requestBody);

    try {
      const response = await fetch(`${config.API_BASE_URL}/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}` // Include the JWT token in the request headers
        },
        body: requestBody
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        alert('Form submitted successfully!');
      } else {
        alert('Error submitting form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form');
    }
  };

  const handleNext = () => {
    const currentQuestions = questionnaires[currentSection].questions;
    let allAnswered = true;

    currentQuestions.forEach(question => {
      if (!shouldShowQuestion(question)) {
        return;
      }
      const value = watch(question.name);

      if (question.required && (!value || (Array.isArray(value) && value.length === 0))) {
        allAnswered = false;
        return;
      }

      if (question.type === 'checkbox') {
        const checkboxValues = watch(question.name);
        if (question.required && (!checkboxValues || !Object.values(checkboxValues).some(val => val))) {
          allAnswered = false;
          return;
        }
        question.options.forEach(option => {
          if (option.requiresText && checkboxValues && checkboxValues[option.value] && !watch(`${question.name}_${option.value}`)) {
            allAnswered = false;
            return;
          }
        });
      }
    });

    if (allAnswered) {
      // Store current form values
      setFormValues({ ...formValues, ...getValues() });
      setCurrentSection(currentSection + 1);
    } else {
      alert('Please answer all required questions.');
    }
  };

  const handleBack = () => {
    // Store current form values
    setFormValues({ ...formValues, ...getValues() });
    setCurrentSection(currentSection - 1);
  };

  if (questionnaires.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Lifestyle Questionnaire
          </Typography>
          {hasSavedData && (
            <Button variant="contained" color="secondary" onClick={loadSavedData}>
              Load Previous Record
            </Button>
          )}
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box key={currentSection} sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom>{questionnaires[currentSection].title}</Typography>
            {questionnaires[currentSection].questions.map((question, index) => {
              if (!shouldShowQuestion(question)) {
                return null;
              }
              const label = question.required ? `${question.question} *` : question.question;
              return (
                <Tooltip title={question.question} key={index} arrow>
                  <Box sx={{ mb: 2 }}>
                    {(() => {
                      switch (question.type) {
                        case 'text':
                          return (
                            <TextField
                              label={label}
                              fullWidth
                              margin="normal"
                              {...register(question.name, { required: question.required ? `${question.question} is required` : false })}
                              error={!!errors[question.name]}
                              helperText={errors[question.name]?.message}
                            />
                          );
                        case 'number':
                          return (
                            <TextField
                              label={label}
                              type="number"
                              fullWidth
                              margin="normal"
                              {...register(question.name, { required: question.required ? `${question.question} is required` : false })}
                              error={!!errors[question.name]}
                              helperText={errors[question.name]?.message}
                            />
                          );
                        case 'select':
                          if (question.name === 'coach_bmi') {
                            return (
                              <TextField
                                label={label}
                                fullWidth
                                margin="normal"
                                value={bmi}
                                InputProps={{
                                  readOnly: true,
                                }}
                                {...register(question.name)}
                              />
                            );
                          } else if (question.name === 'coach_absiz_score') {
                            return (
                              <TextField
                                label={label}
                                fullWidth
                                margin="normal"
                                value={absiZScore}
                                InputProps={{
                                  readOnly: true,
                                }}
                                {...register(question.name)}
                              />
                            );
                          } else if (question.name === 'coach_waisthip_ratio') {
                            return (
                              <TextField
                                label={label}
                                fullWidth
                                margin="normal"
                                value={waistHipR}
                                InputProps={{
                                  readOnly: true,
                                }}
                                {...register(question.name)}
                              />
                            );
                          }

                          return (
                            <FormControl fullWidth margin="normal">
                              <InputLabel>{label}</InputLabel>
                              <Controller
                                name={question.name}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    label={question.question}
                                    error={!!errors[question.name]}
                                  >
                                    {question.options.map((option, idx) => (
                                      <MenuItem key={idx} value={option}>{option}</MenuItem>
                                    ))}
                                  </Select>
                                )}
                              />
                            </FormControl>
                          );
                        case 'date':
                          return (
                            <TextField
                              label={label}
                              type="date"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...register(question.name)}
                              defaultValue={new Date().toISOString().split('T')[0]} // Set default date to today in YYYY-MM-DD format
                            />
                          );
                        case 'time':
                          return (
                            <TextField
                              label={label}
                              type="time"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...register(question.name)}
                            />
                          );
                        case 'checkbox':
                          return (
                            <FormControl component="fieldset" margin="normal">
                              <Typography>{label}</Typography>
                              <FormGroup>
                                {question.options.map((option, idx) => (
                                  <div key={idx}>
                                    <FormControlLabel
                                      control={
                                        <Controller
                                          name={`${question.name}.${option.value}`}
                                          control={control}
                                          render={({ field }) => (
                                            <Checkbox
                                              {...field}
                                              value={option.value}
                                              checked={field.value || false}
                                            />
                                          )}
                                        />
                                      }
                                      label={option.value}
                                    />
                                    {option.requiresText && watch(`${question.name}.${option.value}`) && (
                                      <TextField
                                        label="Please specify"
                                        fullWidth
                                        margin="normal"
                                        {...register(`${question.name}_${option.value}`, { required: true })}
                                        error={!!errors[`${question.name}_${option.value}`]}
                                        helperText={errors[`${question.name}_${option.value}`]?.message}
                                      />
                                    )}
                                  </div>
                                ))}
                              </FormGroup>
                            </FormControl>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </Box>
                </Tooltip>
              );
            })}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            {currentSection > 0 && (
              <Button variant="contained" onClick={handleBack}>Back</Button>
            )}
            {currentSection < questionnaires.length - 1 && (
              <Button variant="contained" color="primary" onClick={handleNext}>Next</Button>
            )}
            {currentSection === questionnaires.length - 1 && (
              <Button type="submit" variant="contained" color="primary">Submit</Button>
            )}
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default Questionnaire;
