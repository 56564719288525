import { useState, useEffect } from 'react';
import { Container, Typography, Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import config from '../config'; // Import the configuration
import { useAuth } from '../auth';

const DownloadReport = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState('');
  const { user } = useAuth(); // Get the access token from useAuth

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/documents`, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setDocuments(data.files);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, [user.accessToken]);

  const handleDownload = async () => {
    if (selectedDocument) {
      try {
        const response = await fetch(`${config.API_BASE_URL}/record/${selectedDocument}`, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = selectedDocument;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error fetching record:', error);
        throw new Error('ERR_CONNECTION_REFUSED');
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" gutterBottom>Download Report</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Select Document</InputLabel>
          <Select
            value={selectedDocument}
            onChange={(e) => setSelectedDocument(e.target.value)}
            label="Select Document"
          >
            {documents.map((doc, index) => (
              <MenuItem key={index} value={doc}>{doc}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownload}
          disabled={!selectedDocument}
          sx={{ mt: 2 }}
        >
          Download
        </Button>
      </Box>
    </Container>
  );
};

export default DownloadReport;
