/* eslint-disable */
// src/aws-exports.js

const awsconfig = {
    region: "us-east-1",  // Your AWS region
    userPoolId: "us-east-1_otHR1tVcs", // Your User Pool ID
    userPoolWebClientId: "2uqdcjf7el3bqpdrbkmvgl9832",    // Your App Client ID
};

export default awsconfig;
