import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  mixins: {
    toolbar: {
      minHeight: 56,
    },
  },
});

export default theme;