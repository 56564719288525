// src/App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline, Box } from '@mui/material';
import { styled } from '@mui/system';
import Login from './components/Login';
import Profile from './components/Profile';
import Questionnaire from './components/Questionnaire';
import DisplayRecord from './components/DisplayRecord';
import AnalyzeReport from './components/AnalyzeReport';
import DownloadReport from './components/DownloadReport';
import Navigation from './components/Navigation';
import Unauthorized from './components/Unauthorized';
import LoginRoute from './routes/LoginRoute';
import NormalUserRoute from './routes/NormalUserRoute';
import AdminRoute from './routes/AdminRoute';
import { AuthProvider } from './auth';
import withErrorBoundary from './withErrorBoundary';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const ToolbarSpacer = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Blue color
    },
    background: {
      default: '#ffffff', // Background color
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const App = () => {
  console.log('App rendering');
  return (
    <Router>
      <Root>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider>
            <Navigation />
            <Content>
              <ToolbarSpacer />
              <Routes>
                <Route path="/login" element={<LoginRoute element={withErrorBoundary(Login)} />} />
                <Route path="/profile" element={<NormalUserRoute element={withErrorBoundary(Profile)} />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/questionnaire" element={<NormalUserRoute element={withErrorBoundary(Questionnaire)} />} />
                <Route path="/display-record" element={<NormalUserRoute element={withErrorBoundary(DisplayRecord)} />} />
                <Route path="/analyze-report" element={<AdminRoute element={withErrorBoundary(AnalyzeReport)} />} />
                <Route path="/download-report" element={<AdminRoute element={withErrorBoundary(DownloadReport)} />} />
                <Route path="/" element={<LoginRoute element={withErrorBoundary(Login)} />} />
              </Routes>
            </Content>
          </AuthProvider>
        </ThemeProvider>
      </Root>
    </Router>
  );
};

export default App;

