// src/routes/LoginRoute.js
import { useAuth } from '../auth';
import { Navigate } from 'react-router-dom';

const LoginRoute = ({ element: Component, ...rest }) => {
  const { user, role } = useAuth();
  console.log('LoginRoute:', { user, role });

  if (!user) {
      console.log('No user found, rendering login component');
      return <Component {...rest} />;
  } else {
      console.log('User found, redirecting to home');
      return <Navigate to="/" />;
  }
};

export default LoginRoute;