import { Container, Typography, Box, Button } from '@mui/material';
import { useAuth } from '../auth';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const { user, setUser, setRole, logout } = useAuth();
    const navigate = useNavigate();

    const handleSignOut = async () => {
        console.log('Signing out...');
        await logout();
        setUser(null);
        setRole(null);
        navigate('/login');
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 5 }}>
                <Typography variant="h4" gutterBottom>Profile</Typography>
                <Typography variant="h6">You are logged in as {user?.username}</Typography>
                <Button variant="contained" color="secondary" fullWidth sx={{ mt: 2 }} onClick={handleSignOut}>Sign Out</Button>
            </Box>
        </Container>
    );
};

export default Profile;
